.container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ion-color-secondary);
}

.container img {
  width: 70%;
  height: auto;
}
