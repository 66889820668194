.upload-item-video-view {
  position: relative;
  margin-right: 26px;
  margin-top: 8px;
}

.upload-item-video-view-override {
  width: 56px !important;
  height: 56px !important;
  & .btn-video {
    width: 50px;
    height: 50px;
  }
}

.upload-item-object-fit-cover {
  object-fit: cover;
}
.upload-item-btn-video {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
.upload-item-icon-btn {
  width: 100%;
  height: 100%;
}

.upload-item-icon-delete-btn {
  width: 20px;
  height: 20px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  left: 78%;
  top: 0px;
}
