.position-absolute-bottom {
    position: absolute;
    bottom: 20px;
    width: 90%;
}
.position-absolute-top {
    position: absolute;
    top: 10px;
    right: 10px;
}
.font-title-confirmed {
    font-size: 24px !important;
    font-weight: 600 !important;
    line-height: 32px !important;
    margin-top: 75px;
}