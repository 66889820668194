.chat_audio-container {
  display: flex;
  align-items: center;
  padding: 3px;
  object-fit: cover;
  height: 30px;
  width: 100% !important;
}

.chat_audio-container__duration {
  color: #03b070;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}