.size-lg {
    width: 100px;
    height: 100px;
}
.size-md {
    width: 40px;
    height: 40px;
}
.size-sm {
    width: 18px;
    height: 18px
}