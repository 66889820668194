.reply-comment-form {
    width: 343px;
    height: 52px;
    border: 1px solid #d8dcdc;
    border-radius: 26px;
    background: #f4f5f5;
    width: calc(100% - 32px);
    margin: 16px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
}

.reply-comment-form input {
    border: none;
    background: none;
    flex: 1;
    height: 100%;
    outline: none;
    color: red;

    &:active,
    &:focus {
        outline: none;
    }
}

.reply-comment-button {
    border: none;
    background: none;
    height: 100%;
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000 !important;
}

.background-comment {
    --background: #FFF1CC !important;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
}

.color-comment {
    color: #FFB800 !important;
}
.text-with-p div p {
    font-size: 16px !important;
}