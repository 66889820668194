/* put global css here*/
.logo {
    width: 200px;
}

.full-width {
    width: 100%;
}

.text-bolder {
    font-weight: bolder;
}

.text-title {
    font-size: 1.6em;
    color: white;
    text-align: center;
}

.text-center {
    text-align: center;
}

.padding-5 {
    padding: 5px;
}

.full-height {
    height: 100%;
}

.width-20 {
    width: 20px;
}

.height-20 {
    height: 20px;
}

.width-25 {
    width: 25px;
}

.height-25 {
    height: 25px;
}

.bg-yellow {
    background-color: #FFB800;
}

.bg-yellow-light {
    background-color: #FFCEEA;
}

.bg-orange {
    background-color: #FB7F26;
}

.bg-white {
    background-color: white !important;
}

.text-size-31 {
    font-size: 31px !important;
}

.text-size-xxl {
    font-size: 40px !important;
}

.text-size-xs {
    font-size: 13px !important;
}

.text-size-14 {
    font-size: 14px !important;
}

.text-size-11 {
    font-size: 11px !important;
}

.text-size-xxs {
    font-size: 10px !important;
}

.text-size-lg {
    font-size: 17px !important;
}

.text-size-md {
    font-size: 18.5px !important;
}

.text-vw-5 {
    font-size: 2.5vh !important;
}

.text-vw-4 {
    font-size: 4vw !important;
}

.text-size-xl {
    font-size: 20px !important;
}

.text-size-23 {
    font-size: 23px !important;
}

.text-size-30 {
    font-size: 25px !important;
}

.text-size-x {
    font-size: 30px !important;
}

.text-black {
    color: black !important;
}

.margin-20 {
    margin: 20px;
}

.border-radius {
    border: solid 1px white;
    border-radius: 30px;
}

.border-radius-10 {
    border-radius: 10px;
}

.border-radius-20 {
    border-radius: 20px !important;
}

.ion-border-radius-20 {
    --border-radius: 20px !important;
}

.ion-border-radius-5 {
    --border-radius: 5px !important;
}

.ion-border-radius-10 {
    --border-radius: 10px;
}

.text-underligne {
    text-decoration: underline !important;
}

.margin-b-5 {
    margin-bottom: 15px;
}

.blue-icon ion-icon {
    color: #3D3BEB !important;
}

.green-icon ion-icon {
    color: #03B070 !important;
}

.white-icon ion-icon {
    color: white !important;
}

.yellow-icon ion-icon {
    color: #FFB800 !important;
}

.text-gray-400 {
    color: #313333;
}

.bg-gray-400 {
    background-color: #313333;
}

.text-gray-200 {
    color: #727272;
}

.text-underline {
    text-decoration: underline;
}

.modal-footer {
    position: fixed;
    bottom: 0px;
    background-color: white;
}

.modal-content {
    position: absolute;
    bottom: 150px;
}

.width-p-95 {
    width: 95%;
}

.width-300 {
    width: 300px;
}

.max-width-250 {
    max-width: 250px;
}

.width-60 {
    width: 60px !important;
}

.height-60 {
    height: 60px !important;
}

.height-72 {
    height: 72px;
}

.border-1 {
    border: 1px solid #FFDA79;
}

.min-height-200 {
    min-height: 200px;
}

.min-height-90 {
    min-height: 90px;
}

.max-height-90 {
    max-height: 90px;
}

.h-150 {
    max-height: 150px !important;
}

.height-auto {
    height: auto;
}

.width-30 {
    width: 30px;
}

.height-30 {
    height: 30px;
}

.border-yellow {
    border: 1px solid #FFB800;
}

.height-100 {
    height: 100px;
}

.height-50 {
    height: 50px;
}

.padding-t-25 {
    padding-top: 25px;
}

.padding-b-10 {
    padding-bottom: 10px;
}

.ion-text-menu {
    font-size: 9px;
    text-wrap: nowrap;
    font-weight: 600;
}

.box-shadow-custom {
    box-shadow: 0px 3.6586666107177734px 10.97599983215332px 0px rgba(0, 0, 0, 0.06);
}

.overflow-auto {
    overflow-y: auto;
}

.overflow-hidden {
    overflow-y: hidden;
}

.max-height-300 {
    min-height: 300px;
}

.max-height-200 {
    max-height: 200px;
}

.padding-b-200 {
    padding-bottom: 200px;
}
.padding-b-300 {
    padding-bottom: 300px;
}

.D16 {
    font-size: 16px;
}

.font-weight-450 {
    font-weight: 450;
}

.font-weight-400 {
    font-weight: 400.25;
}

.margin-t-20 {
    margin-top: 20px;
}

.margin-t-25 {
    margin-top: 25px;
}

.margin-rl-10 {
    margin-left: -10px;
}

.padding-l-10 {
    padding-left: 10px;
}

#popover-content {
    --width: 170px;
}

.border-b-1 {
    border-bottom: 1px solid gray;
}

.width-120 {
    width: 120px;
}

.width-100 {
    width: 100px;
}

.not-transorm-text {
    text-transform: none !important;
}

.title-blue {
    font-size: 24px;
}

.pagination-custom {
    display: inline-block !important;
    width: 56px !important;
    height: 4px !important;
    margin: 0px 4px !important;
    border-radius: 43px !important;
}

.card-no-border {
    box-shadow: none !important;
}

.padding-10 {
    padding: 10px;
}

.point-separator {
    width: 5px;
    height: 5px;
    background-color: var(--ion-color-secondary);
    border-radius: 50%;
    margin: auto 8px;
}

.white-separator {
    width: 3px;
    height: 3px;
    background-color: white;
    border-radius: 50%;
    margin: auto 2px;
}

.padding-vw {
    padding: "0.5vw 1vw"
}

.border-blue {
    border: 1px solid #3D3BEB;
}

.margin-t-5 {
    margin-top: 5px;
}

.margin-t-15 {
    margin-top: 15px;
}

.margin-y-20 {
    padding: 20px 0;
}

.margin-l-20 {
    margin-left: 20px;
}

.width-20 {
    width: 20px;
}

.height-20 {
    height: 20px;
}

.border-green {
    border: 1px solid #03B070;
}

.text-blue {
    color: #3D3BEB;
}

.text-green {
    color: #03B070;
}

.no-margin-t-10 {
    margin-top: -10px;
}

.margin-r-20 {
    margin-right: 20px;
}

.margin-rt-150 {
    margin-top: -150px !important;
}

.overflow-hidden {
    overflow: hidden;
}

.border-radius-round {
    border-radius: 50%;
}

.button-no-border {
    --background: #F8F8F8 !important;
    --box-shadow: none;
}

.relative-h-150 {
    position: relative !important;
    height: 150px !important;
}

.p-relative {
    position: relative !important;
}

.z-top {
    z-index: 50 !important;
}

.flex-end {
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-end !important;
}

.padding-x-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.width-p-80 {
    width: 80%;
}

.width-p-90 {
    width: 90%;
}

.bg-blue {
    background: #3D3BEB !important;
}

.item-bg-blue {
    --ion-item-background: #3D3BEB;
}

.text-white {
    color: white !important;
}

.width-p-80 {
    width: 80%;
}

.margin-x-20 {
    margin-left: 20px;
    margin-right: 20px;
}

.padding-safearea {
    padding-top: var(--ion-safe-area-top, 0) !important;
}

.text-capitalize::first-letter {
    text-transform: capitalize !important;
}

.transform-component-img {
    height: "auto";
    width: "auto";
    object-fit: "contain";
    min-width: 150px;
}

.min-h-100 {
    min-height: 100px;
}

.min-w-150 {
    min-width: 150px;
}

.footer-private-dispo {
    background-color: var(--ion-color-warning) !important;
}

.accepted-dispo {
    --background: white !important;
    --color: var(--ion-color-warning) !important;
}

.refused-dispo {
    --background: var(--ion-color-warning);
    --color: white;
    border: 2px solid white;
    border-radius: 10px;
}

.d-flex-all-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .footer-private-dispo::before {
    content: ' ';
    background-color: white;
    width: 100%;
    height: 7px;
    position: absolute;
    border-radius: 25%;
    top: 0px;
    z-index: 1;
    background-image: none !important;
} */

.footer-curve {
    position: relative;
    bottom: 16px;
    padding: 10px;
    z-index: 0;
    box-shadow: rgb(255, 184, 0) 0px 10px 0px
}

.notification-padding {
    padding: 10px 15px
}

.button-footer {
    --background: white !important;
    box-shadow: none;
}

.border-radius-left {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.position-parent-relative {
    position: relative;
}

.useOne::after {
    content: " ";
    position: absolute;
    height: 24px;
    width: 1px;
    background-color: var(--ion-color-dark);
    border-radius: 5px;
    right: 5px
}

.border-radius-right {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.position-icon {
    position: absolute;
    bottom: 30%;
    right: 10px;
}

.chip-filter {
    --background: #FFF1CC;
    border: 1px solid var(--ion-color-warning) !important;
}

.selected-icon {
    filter: hue-rotate(180deg);
}

.content-blur {
    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(10px);
    z-index: 1;
}

.bullet-container-notif {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 3px;
    right: 25%;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: rgb(0 190 110);
    color: white;
    font-size: 11px;
}

.h-250 {
    height: 250px !important;
}

.h-40 {
    height: 40px !important;
}

.h-300 {
    height: 300px !important;
    max-height: 300px !important;
}
.margin-b-100 {
    margin-bottom: 100px;
}

.margin-b-200 {
    margin-bottom: 200px;
}

.margin-b-300 {
    margin-bottom: 300px;
}

.padding-l-5 {
    padding-left: 5px;
}

.padding-l-3 {
    padding-left: 3px;
}

.margin-l-5 {
    margin-left: 5px;
}

.white-button {
    --background: white !important;
    --box-shadow: none;
}

.bg-gray {
    background-color: #ECEBFD;
}

.text-shadow{
    text-shadow: 2px 2px 6px #000000;
}

.border-radius-top-10 {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}

.container-image {
    position: relative;
    width: 100%;
    height: 134px;
}

.image-card-custom {
    display: flex;
    height: 100%;
    width: 100% !important;
    object-fit: cover !important;
}

.relative-block {
    display: block;
    position: absolute;
    z-index: 20;
    right: 0;
}

.post-container-image {
    position: relative;
    width: 100%;
    height: 300px;
}

.button-bg-black-shade {
    --background: var(--ion-color-dark-shade);
}

.border-black-shade {
    border: 2px solid var(--ion-color-dark-shade);
    border-radius: 10px;
}

.button-bg-white {
    --background: white;
}

.text-black-shade {
    --color: var(--ion-color-dark-shade);
}

.ion-center {
    display: flex;
    justify-content: center;
}

.border-radius-20 {
    border-radius: 20px;
}

.relative {
    position: relative;
}

.ion-btn-clear {
    cursor: pointer;
    position: absolute;
    top: 0;
    width: 40px;
    height: 40px;
    right: 5px;
}

.ion-flex-container {
    display: flex !important;
}

.flex-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cursor-pointer {
    cursor: pointer;
}
.wrap-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

img {
    -webkit-user-drag: none !important;
}
.chat_label-expired {
    position: sticky;
    top: 0;
    --background: #D9D9D9;
    --color: #313333;
    padding: 10px 15px;
    height: auto;
    z-index: 11;
}
.button-no-border-radius {
    --border-radius: 0px !important
}
.background-grey {
    --background: #F8F8F8 !important;
}
.border-shadow-comment {
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
}
.text-with-p div p {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}
.margin-post-comment {
    margin: 10px 0px 10px;
}
.text-comment-post {
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    font-family: Outfit;
}
.text-black-comment {
    color: #323232;
    font-weight: 500;
}
.background-green {
    --background: var(--ion-color-primary);
}
.btn-white {
    --background: white !important;
    --color: var(--ion-color-primary) !important;
}
.active-user-selection{
    user-select: text !important;
}
.col-card > *:first-child {
    padding-left: 0px !important
}
.hide-scrollBar::-webkit-scrollbar {
    display: none;
}
.col-card-no-margin *:first-child {
    margin-top: 0px !important
}
.icon-shadow{
    filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.9));
}

.gallery-container{
    position: relative;
    overflow: hidden;
    display: flex;
    flex-grow: 1;
    height: 100%;
    flex-direction: column
}
.gallery-btn-action{
    padding-top: 5px;
    padding-bottom: 50px;
}
.custom-loader{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}