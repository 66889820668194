.image-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    margin-left : 10px;
    margin-right: 10px;
    border-radius: 10px;
    overflow: hidden;
}

.image-wrapper {
    width: calc(32.93%);
    max-height: 110px;
    position: relative;
    overflow: hidden;
}

.image-wrapper img,
.video-view {
    flex: 1;
    width: 100%;
    height: 100%;
}

.image-wrapper img{
    min-height: 100px;
    object-fit: cover;
    object-position: center;
}
