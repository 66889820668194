.video-view {
    width: 100% !important;
    height: 100% !important;
    position: relative;
}
.oject-fit-cover {
    object-fit: cover !important;
}
.btn-video {
    position: absolute;
    width: 65px;
    height: 65px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}
.icon-btn {
    width: 100%;
    height: 100%
}
.h-fixed-150 {
    height: 150px !important;
}

.reactplayer-video video {
    height: inherit !important;
}