.img-container{
    position: relative;
    width: 100%;
}
.galery-post {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 80px);
    grid-gap: 2px;
}

.galery-post-item {
    border-radius: 8px;
    overflow: hidden;
}
.galery-post-main {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 4;
}
.galery-post-1 {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 2;
}
.galery-post-2 {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 3;
}
.galery-post-3 {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 3;
    grid-row-end: 4;
}
.galery-post-4 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 4;
    grid-row-end: 5;
}
.galery-post-5 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 5;
}
.galery-post-6 {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 4;
    grid-row-end: 5;
}
.galery-post-7 {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 4;
    grid-row-end: 5;
}
.close-img{
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 25px;
}
.post-form .ql-editor {
    background-color: white !important 
}