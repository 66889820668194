
.container-interest {
    display: block;
    text-align: center;
}
.container-picto-interest {
    background-color: #FFF1CC;
    display: flex;
    width: 48px;
    height: 48px;
    align-items: center;
    justify-content: center;
    margin: auto;
    border-radius: 10px 
}
.img-picto {
    filter: invert(79%) sepia(12%) saturate(3580%) hue-rotate(0deg) brightness(105%) contrast(118%);
    width: 24px;
    height: 24px;
}

.font-interest-picto {
    font-size: 14px;
    color: #313333;
    font-weight: 500;
}
.edit-btn-picto {
    width: 21px;
    height: 21px;
}
.container-picto-interest-border {
    background-color: white !important;
    border: 1.5px solid #D9D9D9;
}
.font-edit-picto {
    color: #727272;
    text-decoration: underline;
    text-underline-offset: 2px;
}