.width-100-percent {
    width: 100%
}
.container-view {
    margin:11px;
    border: 2px solid var(--ion-color-dark);
    border-radius: 30px;
    overflow: hidden;
}
.title-card-detail {
    font-size: 17px !important;
    color: var(--ion-color-dark);
    font-weight: 600 !important;
    margin-bottom: 14px;
}
.subtitle-card-detail {
    font-size: 13px !important;
    color: #727272;
    margin-bottom: 15px;
    font-weight: 600 !important;
}
  
  .image-onboarding {
    width: 100%;
    height:auto;
    object-fit: cover;
  }
  .page-blue {
    background: var(--ion-color-secondary) !important;
  }
  
  .title-onboarding {
    font-size: 24px;
    font-weight: 600;
  }
  
  .description-onboarding {
    font-size: 18px;
    font-weight: 400;
    width: 310px;
    margin: 25px auto;
  }

.padding-card-detail {
    padding: 15px !important;
}
.progress-bar-custom {
  border-radius: 10px;
  height: 10px;
}
.progress-bar-custom .progress {
  border-radius: 10px;
}