.fullContent {
  width: 100%;
  height: 100%;
  border-radius: 7px;
}
.container-row {
  overflow: hidden;
  border: 4px solid white;
  border-radius: 7px;
}
.border-image {
  border: 4px solid var(--ion-color-secondary);
  box-sizing: border-box;
}