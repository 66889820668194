.chat__search-box {
  background-color: #d9d9d9;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin: 15px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.chat__search-box__search-label-container {
  display: flex;
  align-items: center;
}

.chat__search-box__search-label {
  color: #313333;
  font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-left: 8px;
  flex: 1;
}

.chat__search-box__search-container {
  display: flex;
  align-items: center;
}

.chat__search-box__searchbar {
  width: 100%;
  position: relative;
}

.chat__search-box__searchbar .searchbar-input {
  text-align: left;
  padding-left: 10px;
}

.chat__search-box__searchbar .searchbar-search-icon {
  right: 10px;
  left: auto;
}

.chat__search-box__loading-spinner {
  position: absolute;
  right: 44px;
  top: 16px;
}
