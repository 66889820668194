.swiper {
  width: 100%;
}
.swiper-pagination {
  position: relative;
  margin-top: 10px !important;
}
.swiper-wrapper {
  align-items: center;
}
.swiper-pagination-bullet {
  background: var(--ion-color-medium-tint) !important;
}

.swiper-pagination-bullet-active {
  background: var(--ion-color-primary) !important;
}

.image-onboarding {
  height:auto;
  width: 90%;
  margin: auto;
  object-fit: cover;
}
.page-blue {
  background: var(--ion-color-secondary) !important;
}

.title-onboarding {
  font-size: 24px;
  font-weight: 600;
}

.description-onboarding {
  font-size: 18px;
  font-weight: 400;
  width: 310px;
  margin: 25px auto;
}
.boarding-container{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bording-content{
  margin: 0;
  position: absolute;
  width: 100%;
}
.bording-content ion-card{
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}