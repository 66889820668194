.icon-position-file {
    position: absolute;
    top: 50%;
    z-index: 11;
    right: 20px;
    transform: translateY(20%);
    font-size: 20px;
}
.container-relative {
    position: relative;
}