.ql-toolbar {
    display: none !important;
}
.ql-container {
    height: 128px;
    border: none !important;
    border-radius: 8px;
    background-color: var(--ion-color-medium);
    font-size: 16px;
    color: var(--ion-color-dark);
    font-family: 'Outfit', sans-serif !important;
    font-weight: 600;
}
.ql-editor.ql-blank::before {
    font-style: normal !important;
    font-weight: 500 !important;
    color: rgba(0,0,0,0.35) !important;
}
.description-message {
    margin-top: 0px !important;
}