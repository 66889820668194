.out-line-selected {
    border-bottom: 2px solid #3D3BEB !important;
    color: #3D3BEB !important;
}

.full-line-selected {
    background-color: #ECEBFD !important;
    color: #3D3BEB !important;
}
.full-width-button {
    border: none;
    width: 100%;
    background-color: white;
    font-size: 14px;
    height: 30px;
    color: var(--ion-color-dark);
    --border-radius: 0px;
}

.ios-width-button {
    border: none;
    width: 100%;
    background-color: white;
    font-size: 16px;
    height: 42px;
    color: var(--ion-color-dark);
    border-radius: 5px;
}
.ios-line-selected {
    background-color: var(--ion-color-warning) !important;
    color: white !important;
}

.out-width-button {
    border: none;
    width: 100%;
    border-bottom: 2px solid #9E9E9E;
    font-size: 10px;
    height: 30px;
    color:#9E9E9E;
    --border-radius: 0px;
    text-transform: uppercase;
}

.grid-container {
    background-color: white;
    border-radius: 6px;
    overflow: hidden;
}
.grid-container-ios {
    border: 2px solid var(--ion-color-warning);
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
}