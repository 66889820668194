.height-p-30 {
    height: 45%;
}
.width-90 {
    width: 98%;
}
.bg-green {
    background: #04B171;
}
.margin-auto {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.bg-white-gradient {
    background: linear-gradient( #F1F1F1, #FFFFFF);
}

.spacing-top {
    margin-top: 0px;
}
.spacing-bottom {
    margin-bottom:15px;
}
.bg-blue-light {
    background-color: #ECEBFD;
}
.margin-bottom-5 {
    margin-bottom: 5px;
}
.ion-icon-blue {
    color: #3D3BEB;
}
.ion-icon-white {
    color: white !important;
}
.blue-text {
    color: #3D3BEB;
}

.button-event-detail {
    --background: #ecebfd;
    min-width: 100%;
    width:100%;
    border-radius: 8px;
    border: 2px solid blue;
    color: blue;
    text-transform: none;
    text-align:left !important;
}
.padding-2 {
    padding: 2%;
}

.text-gray {
    color : gray;
}
.text-bold {
    font-weight: bold;
}
.text-uppercase {
    text-transform: uppercase;
}
.color-yellow {
    color: #FFB800;
}
.color-white {
    color : white;
}
.height-10 {
    height: 10px;
}
.height-180 {
    height : 180px;
}
.border-radius-bottom {
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}
.border-radius-top {
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
}
.margin-0 {
    margin: 0;
}
.padding-0 {
    padding: 0;
}
.height-80 {
    height: 80px;
}
.height-90{
    height: 90px;
}
.height-75{
    height: 75px;
}

.height-60 {
    height: 60px;
}

.height-70 {
    height: 70px;
}

.min-width-p-100 {
    min-width: 100%;
}
.max-width-200{
    max-width: 200px;
}

.min-height-p-100 {
    min-height : 70px;
}

.ion-bg-white {
    --background: #ecebfd;
}

.ion-bg-yellow {
    --background: #FFB800;
}

.text-white{
    --color: white;
}
.rounded-image {
    border-radius: 50%; /* Applique une bordure arrondie pour rendre l'image ronde */
}

.bg-primary {
    background-color: #F7F7F7;
}
.bg-pink-light {
    background-color: #FFF7E5;
}
.bg-pink {
    background-color: #FF99CF;
}
.ion-card-no-border {
    box-shadow:none !important;
}

.text-yellow {
    color: var(--ion-color-warning);
}
.text-pink {
    color: #FF99CF;
}
.text-primary {
    background-color: #F7F7F7;
}
.margin-t-50 {
    margin-top: 50px;
}
.padding-left-10{
    padding-left: 10px;
}
.height-40{
    height: 40px;
}
.min-height-120{
    min-height: 120px;
}
.width-40{
    width: 40px;
}
.text-gray-400{
    color: #313333;
}
.ligne-h-md{
    line-height: 1.4em;
}
.ion-border-white{
    --border-color:white;
    --border-style: solid;
    --border-width: 2px;
}
.border-round{
    border:1px solid #FFF7E5;
    border-radius: 50%;
    width:70px;
    height:70px;
}
.margin-l-10{
    margin-left : 10px;
}
.margin-x-10{
    margin : 0 10px !important;
}
.margin-r-5{
    margin-right : 5px;
}
.margin-b-3{
    margin-bottom: 3px;
}
.no-margin-t-12{
    margin-top: -12px;
}
.no-margin-t-10{
    margin-top: -10px;
}
.no-margin-t-15{
    margin-top: -15px;
}
.no-margin-t-8{
    margin-top: -8px;
}
.startStyle{
    font-size: 14vw;
}
.ion-icon-yellow{
    color: #FFB800;
}
.padding-t-20{
    padding-top: 20px;
}
.height-60{
    height: 48px !important;
}
.width-270{
    width: 270px;
}
.min-width-270{
   min-width: 270px;
}
.width-auto{
    width : 100%;
}
.width-p-96{
    width : 96%;
}
.a-propos{
  font-family: 'Outfit', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}
.padding-y-5{
    padding: 5px 0;
}
.margin-y-5{
    margin: 10px 0;
}