.chat__message-item__file__row {
  /* height: 200px; */
  /* height: 50vw; */
}

.chat__message-item__img-single {
  object-fit: cover;
  height: 75vw;
}

.chat__message-item__img__mine {
  width: 75vw;
  object-fit: cover;
  border-radius: 8px 4px 8px 8px;
  border: 1px solid var(--ion-color-primary);
  overflow: hidden;
}

.chat__message-item__img__not-mine {
  width: 75vw;
  object-fit: cover;
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 2px 10px 10px 2px;
  background: var(--gris-white, #fff);
}

.chat__message-item__img-1 {
  object-fit: cover !important;
  height: 50vw !important;
}

.chat__message-item__img-2 {
  object-fit: cover;
  height: 37vw;
}

.chat__message-item__img-3 {
  object-fit: cover;
  height: 25vw;
}

.chat__message-item__img-4 {
  object-fit: cover;
  height: 37vw;
}

.chat__message-item__video {
  width: 100%;
  object-fit: cover;
}

.chat__message-item__col {
  height: 100%;
}

.chat__message-item__img__plusplus {
  top: 0%;
  position: absolute;
  color: #fff;
  font-family: Encode Sans;
  font-size: 34.176px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.chat__message-item__file__grid-container {
  display: flex;
}

.chat__message-item__file__grid-container-start {
  justify-content: start;
}

.chat__message-item__file__grid-container-end {
  justify-content: end;
}

.chat__message-item__file-icon {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 44%;
  left: 44%;
}

.chat__message-item__img-border {
  border-radius: 8px 4px 8px 8px;
  border: 2px solid var(--ion-color-primary);
}

.chat__message-item__img-border-single-elt::part(image) {
  border-radius: 7px 3px 7px 7px;
}

.chat__message-item__img-border-first-elt::part(image) {
  border-radius: 7px 0px 0px 7px;
}

.chat__message-item__img-border-third-elt::part(image) {
  border-radius: 0px 3px 7px 0px;
}

.chat__message-item__pair__img-border-first-elt::part(image) {
  border-top-left-radius: 7px;
}

.chat__message-item__pair__img-border-second-elt::part(image) {
  border-top-right-radius: 3px;
}

.chat__message-item__pair__img-border-third-elt::part(image) {
  border-bottom-left-radius: 7px;
}

.chat__message-item__pair__img-border-fourth-elt::part(image) {
  border-bottom-right-radius: 7px;
}
