.chat__audio-container {
  display: flex;
  align-items: center;
  padding: 8px;
}

.chat__audio-container__duration {
  color: #03b070;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-left: 8px;
  margin-right: 8px;
}
