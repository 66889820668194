/* ToastsContainer */
.toasts-container {
    display: flex;
    flex-direction: column-reverse;
    row-gap: 12px;
    position: fixed;
    z-index: 9999;
}

.top-right {
    top: 16px;
    right: 16px;
}

.top-left {
    top: 16px;
    left: 16px;
}

.top-center {
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
}

.bottom-left {
    bottom: 60px;
    left: 16px;
}

.bottom-center {
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
}

.bottom-right {
    bottom: 60px;
    right: 16px;
}

/* Toast */
.toast {
    display: flex;
    align-items: center;
    background-color: #F8F8F8;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 8px  16px 8px 16px;
    position: relative;
    width: 320px;
    overflow: hidden;
}

.toast-message {
    color: #151626;
    font-size: 13px;
    font-weight: 500;
    margin-left: 12px;
}

.toast-progress {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: rgba(0, 0, 0, 0.1);
}

