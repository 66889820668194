.chat__message-box__file-item-container {
  display: flex;
  border-radius: 4px;
  border: 1px solid #ffffffa8;
  align-items: center;
  margin-right: 15px;
  width: 60vw;
  position: relative;
  margin-top: 15px;
}

.chat__message-box__file-item-content-container {
  display: flex;
  flex-direction: column;
  padding: 6px;
  max-width: 100%;
}

.chat__message-box__fileinfo {
  max-width: 85%;
  overflow-x: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

.chat__message-box__fileinfo__main {
  color: #fff;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.chat__message-box__fileinfo__meta {
  color: #fff;
  font-family: Outfit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-top: 5px;
}

.chat__msg-file__file-close-btn-container {
  display: flex;
  width: 20px;
  height: 20px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  left: 95%;
  top: -14px;
}
