.chat__drawer__content-content {
  margin-top: 10vh;
}

.chat__drawer__dispo-card {
  border: 1px solid #d9d9d9;
  & .card-dispo-content-with-border-left {
    border-left: 1px solid #d9d9d9;
  }
}

.chat__drawer__dispo-button {
  border-radius: 10px;
  background: #03b070;
}

.chat__drawer__custom-ion-button {
  width: 75%;
  margin: 0 auto;
}

.chat__drawer__participants-row {
  margin-top: 40px;
  margin-bottom: 30px;
}

.chat__drawer__participants-row__content {
  color: #313333;
  leading-trim: both;
  text-edge: cap;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-left: 16px;
}

.chat__drawer__setting-label {
  color: #313333;
  font-family: Outfit;
  font-size: 18px;
  font-style: normal;
}

.chat__drawer__silent-label {
  color: #313333;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.chat__drawer__silent-container {
  border-bottom: 1px solid #3d3beb;
}
