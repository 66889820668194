.widthIcon {
  width: 30%;
  height: auto;
}

.margin-auto {
  position: relative;
  margin: 0 auto;
  display: block;
}

.font-description {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  margin: 32px 0;
}
.margin-top-header {
  margin-top: 100px !important;
}
.input-otp {
  background-color: var(--ion-color-medium);
  border: none;
  font-size: 20px;
  margin: 5px 10px 5px 10px;
  border-bottom: 1px solid var(--ion-color-dark);
  border-radius: 0px;
  height: 100% !important;
  width: 100% !important;
  -webkit-user-select: none;
  user-select: none;
  
}
.input-otp:focus {
  outline: none !important;
  caret-color: transparent;
}

.ios .input-otp {
  padding: 0 !important;
  height: 100% !important;
}

.container-otp {
  background-color: var(--ion-color-medium);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding:0 5px;
  user-select: none !important;
  -webkit-user-select: none !important;
}
.custom-otp-input input,
.custom-otp-input:focus,
.custom-otp-input {
  user-select: none !important;
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
}

.code-invalide {
  border: 2px solid var(--ion-color-danger) !important;
  border-radius: 10px;
}
.input-otp-inside::selection {
  background-color: transparent;
}
.input-otp::selection {
  background-color: transparent;
}
.text-center ion-button.text-black {
  --padding-top: 0;
  height: auto; 
}