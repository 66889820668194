ion-range {
  --bar-background: var(--ion-color-medium-tint);
  --bar-background-active: var(--ion-color-success);
  --bar-height: 8px;
  --bar-border-radius: 8px;
  --knob-background: var(--ion-color-success);
  --knob-size: 20px;
}
ion-range::part(tick) {
  background: #a2d2ff;
}

ion-range::part(tick-active) {
  background: #bde0fe;
}

ion-range::part(pin) {
  color: var(--ion-color-success);
  font-size: 1em;
  border-radius: 50%;
  transform: scale(1.01);
  top: -20px;
  font-family: 'Outfit', sans-serif;
}

ion-range::part(pin)::before {
  content: none;
}

ion-range::part(pin)::after {
  content: "km";
}