.chat__mute_modal__header {
  margin-bottom: 20px;
}

.chat__mute-modal__loading-spinner {
  position: absolute;
  right: 20px;
  top: 10px;
}

.chat__mute-modal_item-container {
  margin-top: 30px !important;
  & .item-lines-default {
    width: 96%;
  }
  & .item-lines-none {
    width: 96%;
  }
}
