.card-dispo-with-border {
    border: 3px solid #3D3BEB;
}

.card-dispo-content-with-border-left {
    border-left: 3px solid #3D3BEB;
}

.title-card-no-message {
    color: #3D3BEB;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.subtitle-card-emplacement {
    color: #727272;
    leading-trim: both;
    text-edge: cap;
    font-family: Outfit;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.subtitle-card-emplacement-value {
    color: #313333;
    leading-trim: both;
    text-edge: cap;
    font-family: Outfit;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
}

.subtitle-card-date { 
    color: #727272;
    leading-trim: both;
    text-edge: cap;
    font-family: Outfit;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; 
}
