.row-comment-hover {
    transition: border 0.3s;
}

.row-comment-hover:hover {
    background-color: #ECEBFD;
    border-radius: 4px;
}

.text-overflow-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}