.full-interest {
    --background: #FFB800;
    --color: white
}
.outline-interest {
    --background: white;
    background: white ;
    --color: #FFB800;
    border: 1px solid #FFB800;
}
.icon-no-selected {
    filter: invert(79%) sepia(12%) saturate(3580%) hue-rotate(0deg) brightness(105%) contrast(118%);
}

.icon-selected {
    filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(305deg) brightness(103%) contrast(101%);
}

.icon-no-selected-green {
    filter: invert(59%) sepia(91%) saturate(4072%) hue-rotate(127deg) brightness(94%) contrast(98%);
}

.full-interest-green {
    --background: #03B070;
    --color: white
}
.full-interest-yellow {
    --background: #FFB800;
    --color: white
}
.outline-interest-green {
    --background: white;
    background: white ;
    --color: #03B070;
    border: 1px solid #03B070;
}
.font-interest {
    font-size: 14px
}