.custom-input {
  border: none !important;
  border-radius: 8px !important;
  padding: 5px !important;
  background-color: var(--ion-color-medium);
}
.custom-input input {
  font-family: 'Outfit', sans-serif !important;
  font-style: normal;
  font-size: 16px;
}
.custom-label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #1f1f1f;
  margin-bottom: 8px;
}
.errorDescription {
  font-size: 12px;
  margin-top: 5px;
  color: var(--ion-color-danger);
}
.bold-input input {
  font-weight: 500 !important;
}