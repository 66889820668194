.container-message {
    font-size: 14px;
    display: flex;
}
.container-message ion-icon {
    margin: auto 0;
}
.margin-left-xs {
    margin-left: 5px;
}
ion-label[color=warning] {
    color: #fe7b31 !important;
}
ion-icon[color=warning] {
    color: #fe7b31 !important;
    fill: #fe7b31 !important;
}