.title-modal-reporting {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    margin-top: 32px;
    margin-bottom: 16px;
}
.label-reporting {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}