.date-time-button-custom {
    padding: 0px 4px;
    height: 56px
}

ion-datetime-button::part(native) {
    height: 100%;
    width: 100%;
    text-align: left;
}
.position-icon-first {
    position: absolute;
    bottom: 30%;
    right: 10px;
}
.main-label {
    font-size: 16px;
    margin-bottom: 0px;
}
.second-label {
    margin-top: 4px !important;
}
