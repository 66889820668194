.display-block {
    display: block;
    width: 100%;
    margin: 10px 0px;
    
}
.color-checkbox-custom {
    --checkbox-background-checked: var(--ion-color-secondary);
}
.color-checkbox-custom::part(container) {
    border: 1px solid var(--ion-color-secondary);
}
.check-box-round {
    --border-width: 0px;
    --border-radius: 50%
}
.card-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: block;
    z-index:101;
    background-color: var(--ion-color-primary);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.card-bottom ion-button {
    --background: white;
  
    --color:  var(--ion-color-primary);
  
    --border-radius: 10px;
    --box-shadow: none;
    height: 50px;
    width: 85%;
    float:right
}

.flex-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.flex-container .bullet {
    width: 40%;
    height: 5px;
    border-radius: 5px;
    background: white;
}
.card-user-viewer {
    max-height: 290px;
    overflow: scroll;
}