.msg-box__file-uploaded-container {
  background-color: #727272;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0px -1px 20px 0px rgba(0, 0, 0, 0.1);
  height: 130px;
  padding: 8px;
  position: absolute;
  top: -130px;
  width: 100%;
  .msg-box__file-uploaded-container__title-container {
    display: flex;
    align-items: center;
    width: 97%;
  }

  .msg-box__file-uploaded-container__title-container__title {
    color: #fff;
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
  }

  .msg-box__file-uploaded-container__close-btn {
    width: 15px;
    height: 15px;
    color: white;
  }
  .msg-box__file-container-wrapper {
    border: none;
    display: flex;
    overflow-x: auto;
    margin-top: 10px;
    align-items: center;
  }
  .msg-box__file-container-wrapper::--webkit-scrollbar {
    width: 0;
  }
}
