body {
  background: #1b1c1c;
  display: grid;
  place-items: center;

  .sound-wave {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bar {
    animation-name: wave-lg;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    background: #fff;
    margin: 0 1.5px;
    height: 10px;
    width: 1px;

    &:nth-child(-n + 7),
    &:nth-last-child(-n + 7) {
      animation-name: wave-md;
    }

    &:nth-child(-n + 3),
    &:nth-last-child(-n + 3) {
      animation-name: wave-sm;
    }
  }
}

@keyframes wave-sm {
  0% {
    opacity: 0.35;
    height: 5px;
  }
  100% {
    opacity: 1;
    height: 12px;
  }
}

@keyframes wave-md {
  0% {
    opacity: 0.35;
    height: 7px;
  }
  100% {
    opacity: 1;
    height: 25px;
  }
}

@keyframes wave-lg {
  0% {
    opacity: 0.35;
    height: 7px;
  }
  100% {
    opacity: 1;
    height: 35px;
  }
}
