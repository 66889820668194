.d-flex {
    display: flex;
    align-items: center;
}
.select-hidden {
    width: 50px;
    position: absolute;
    opacity: 0;
}
.flag-icon {
    border-radius: 3px;
}
.country-code {
    font-size: 16px;
    margin-left: 5px;
    color: var(--ion-color-dark);
}
.input-phone-custom {
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    font-size: 16px;
}
.input-parent-custom {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    padding: 5px !important;
    background-color: var(--ion-color-medium);
}
.code-parent-custom {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
    padding: 5px !important;
    background-color: var(--ion-color-medium-shade);
}
.select-data .action-sheet-wrapper .action-sheet-container .action-sheet-group-cancel {
    margin-bottom: calc(var(--ion-safe-area-bottom) + 35px) !important;
}