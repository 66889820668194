.full-media {
    --background: black !important;
    --backdrop-opacity: 0.5;
}
.full-media > div {
    justify-content: center;
    align-items: center;
}
.full-media::part(content) {
    background-color: transparent !important;
}
.full-media .modal-wrapper {
    background: transparent !important;
}
.full-media .swiper {
    background-color: transparent;
}
.btn-media {
    position: absolute;
    top: 10px;
    right: 0px;
    height: 65px;
    width: 65px;
    z-index: 20
}

.icon-media {
    font-size: 50px;
    color: white !important;
}

.full-media .swiper-pagination {
    margin-top: 0 !important;
    position: absolute !important;
}

.btn-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0px;
    height: 65px;
    width: 65px;
    z-index: 20
}
.btn-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
    height: 65px;
    width: 65px;
    z-index: 20
}