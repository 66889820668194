.round-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #f2f2f2;
  border-radius: 50%;
}
.square{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #f2f2f2;
  padding: 10px;
}
.custom-label-time {
  font-size: 12px;
  margin-top: 5px;
}

.bubble::before {
  content: " ";
  width: 10px;
  height: 10px;
  background-color: var(--ion-color-success);
  border-radius: 50%;
  position: absolute;
  top:0px;
  left:0px;
}
.margin-bottom-card-minimal {
  margin-bottom: 8px
}
.max-width-notif {
  max-width: 85%;
}
.font-notif-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}
.no-padding-top {
  padding-top: 0px !important;
}
ion-grid.unseen {
  background-color: #f2fff2;
}
ion-icon.notification_icon {
  font-size: 32px;
  border: 1px solid #e7e4e4;
  border-radius: 5px;
  padding: 3px;
  background-color: #e7e4e4;
}