.imgPDP-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: var(--ion-color-medium);
    overflow: hidden;
}
.icon-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 25px;
}
.galery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 101px);
    grid-gap: 14px;
}
.galery-item {
    border-radius: 8px;
    overflow: hidden;
}
.galery-main {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
}
.galery-1 {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
}
.galery-2 {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3;
}
.galery-3 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
}
.galery-4 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
}
.galery-5 {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 4;
}

.tag-color-main {
    background-color: #FFF1CC !important;
    color: var(--ion-color-dark);
    text-transform: capitalize;
}