.chat__message-box__files__img-container {
  margin-top: 8px;
  margin-right: 30px;
  position: relative;
}

.chat__message-box__files__img-container__img {
  width: 56px;
  height: 56px;
  object-fit: cover;
}

.chat__msg-file__image-close-btn-container {
  display: flex;
  width: 20px;
  height: 20px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  left: 78%;
  top: -12px;
}
