.full-slider {
    --background: rgba(2, 2, 2, 0.2);
    backdrop-filter: blur(1px);
}

.swiper-button-prev {
    background-image: url("../../assets/icons/circle-left.svg");
    width: 60px;
}

.swiper-button-next {
    background-image: url("../../assets/icons/circle-right.svg");
    width: 60px;
}

.swiper-button-next::after,
.swiper-button-prev::after {
    content: "";
}

.swiper-pagination-bullet{
    background-color: yellow !important;
}