.chat__details__pdf-preview {
}

.chat__details__pdf-preview__info-container {
  display: flex;
  align-items: center;
}

.chat__details__pdf-preview__info-container__title {
  width: 95%;
  margin-left: 7px;
}

.chat__details__pdf-preview__meta {
  margin-left: 10px;
}
