.flex-vertical-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.width-100{
    width: 100px;
}

.height-100{
    height: 100px;
}
.margin-b-20{
    margin-bottom: 20px;
}
.custom-item{
    border-bottom: "1px solid blue" !important;
}

.custom-item ion-icon{
    color: #3D3BEB;
}
.icon-s-16{
    font-size: 16px;
}