.title-dispo {
    background-color: rgba(255, 184, 0, 1)
  }
.text-white {
    color: white;
}
.margin-none {
    margin: 0;
}
.svg-position{
    position: relative;
    top: 30px;
}
.width-p-90 {
    width : 90%;
}
.padding-10 {
    padding: 10px;
}
.border-radius-top-20 {
    border-top-left-radius: 20px ;
    border-top-right-radius: 20px;
}
.border-radius-t-20 {
    border-top-left-radius: 20px ;
    border-top-right-radius: 20px ;
}
.bg-green{
    background-color: #03B070;
}
.height-p-60 {
    height: 60%;
}
.width-p-80{
    width: 80%;
}
.margin-t-130{
    margin-top: 130px;
}
.margin-b-50{
    margin-top: 50px;
}
.height-300{
    height: 300px;
}
.max-height-300{
    height: 300px;
}
.padding-3 {
    padding : 3px;
}
.margin-b-300 {
    margin-bottom: 300px;
}
.shadow-bottom {
    box-shadow: 1px 1px 20px grey;
    z-index:50;
}
.min-width-p-80 {
    min-width: 80%;
}
.button-bg-green {
    --background:  #03B070;
}
.button-bg-black {
    --background: black ;
}
.button-bg-blue {
    --background: #3D3BEB;
}
.no-uppercase{
    text-transform: none;
}
.margin-b-10 {
    margin-bottom: 10px;
}
.margin-b-15{
    margin-bottom: 15px;
}
.padding-t-50{
    padding-top: 50px;
}
ion-text > h4 {
    font-weight: 600 !important;
    font-size: 18px !important;
}