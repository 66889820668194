ion-header ion-toolbar {
  border-radius: 0px 0px 16px 16px;
}
ion-header ion-title {
  text-align: left;
  padding-inline: 0;
 /* text-transform: lowercase; */
}

ion-header ion-title span {
  overflow: hidden;
  display: block;
  width: auto;
  white-space: normal;
  text-overflow: ellipsis;
}
ion-header ion-title span.has-button {
  width: 55vw !important;
}

ion-header ion-title span.title-only {
  font-size: 20px;
}
ion-header ion-title.with-logo {
  padding-left: 40px !important;
}
ion-header ion-title.without-logo {
  padding-left: 0 !important;
}
ion-header ion-title span:first-letter {
  text-transform: capitalize;
}
ion-header ion-title span {
  text-transform: lowercase;
}
ion-header ion-button[fill=solid][color=light] button {
  --background: white !important;
  background-color: white !important;
}
.opacity-0 {
  opacity: 0 !important;
}
.position-child {
  position: absolute;
  width: 90%;
  margin: auto;
  display: block;
  left: 50%;
  top: 50%;
  align-items: center;
  transform: translate(-50%, -50%);
}