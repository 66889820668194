.font-card-minimal {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    color: var(--ion-color-dark);
    text-transform: none !important;
    margin-bottom: 0px !important;
}
.font-title-card-minimal {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color: var(--ion-color-secondary);
    margin-bottom: 2.5px;
}
.d-flex-notification {
    display: flex;
}
.fill-image {
    object-fit: cover;
    display: fill;
}

.tag-position {
    position: absolute;
    bottom: 5px;
    left: 5px;
    z-index: 11;
    background-color: var(--ion-color-primary);
    color: var(--ion-color-light);
    border-radius: 6px !important;
    text-transform: uppercase;
    height: 20px !important;
    font-size: 10px
}
.font-weight-700 {
    font-weight: 700;
}
.font-bolder {
    font-weight: 600 !important;
    color: #727272 !important;
}
.border-card-minimal {
    border-radius: 8px;
    border: 2px solid var(--ion-color-secondary);
    box-shadow: none;
}
ion-card.border-card-minimal  {
    max-height: 160px;
}
.margin-top-minimal {
    margin-top: 4px;
}
.margin-top-minimal-city {
    margin-top: 5px;
}
.padding-card-minimal {
    padding: 9px 
}
.header-title-style {
    flex-direction: column;
}
.border-right-image {
    border-right: 2px solid var(--ion-color-secondary);
}
.margin-card-city-sm {
    margin-top: 3px;
}