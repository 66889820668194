.grey-mode {
    filter: grayscale(80%);
}
.card-custom {
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 16px;
    background: #FFF;
}
.font-subtitle {
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 14px !important;
}

.font-title {
    font-size: 18px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 140% !important;
}
.font-grey-light {
    color: #727272 !important
}
.font-text {
    color: #313333 !important
}
.font-text::first-letter {
    font-size: uppercase !important;
}
.ion-item-min {
    --min-height: 0 !important
}
.avatar-card {
    width: 18px !important;
    height:18px !important;
    border-radius: 50% !important;
}
.flex-card-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
}
.margin-left-label {
    margin-bottom: 0 !important;
    margin-left: 5px !important
}
.margin-top-sm {
    margin-top: 10px
}
.margin-top-xs {
    margin-top: 3px
}
.padding-card {
    padding: 8px !important
}
.d-flex-title {
    display: flex;
}
.border-white{
    border: 1px solid white;
}
.float-tr{
    position: absolute;
    top : 0;
    right: 0;
}
.float-bl{
    position: absolute;
    bottom: 0;
    left: 0;
}
.float-br{
    position: absolute;
    bottom: 0;
    right: 0;
}

.bg-pending {
    background-color: orange;
}
.weather-container ion-icon {
    height: 40px;
    width: 40px;
}