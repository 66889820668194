ion-datetime-button {
  background: var(--ion-color-medium);
}
ion-datetime-button::part(native) {
  background: var(--ion-color-medium);
  padding: 0;
}
.d-flex-container {
  display: flex;
}
.margin-auto-container {
  margin: auto
}
.col-3 {
  width: 85%;
}
.icon-date-size {
  font-size: 24px;
}