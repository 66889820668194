.imgPDP {
    width: 186px;
    height: 186px;
}
.imgPDP ion-avatar {
    width: 70px;
    height: 70px;
}
.imgPDP-1 {
    width: 265px;
    height: 265px;
}
.imgPDP-1 ion-avatar {
    width:236px;
    height:236px;
    --border-radius: none !important;
}
.position-image-container {
    overflow: visible;
    position: relative;
    width: auto;
    margin: 40px auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.container-image-pdp {
    width:auto;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    border: 2px dashed var(--ion-color-primary);
    border-radius: 12px;
}

.container-image-pdp div div {
    width: 265px;
    height: 265px;
}

.button-reset-image {
    --border-radius: 50%;
    position: absolute;
    top: -5px;
    width: 23px;
    height: 23px;
    padding-inline: 0;
    transform: translateX(130px)
}
.ion-range-custom{
   width: 200px;
   margin:auto;
}
.avatar-image .img{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
}
.ion-range-child {
    height: 20px;
}
.margin-top-20 {
    margin-top: 20px !important;
}
.container-image-pdp-default {
    border-radius: 12px;
    border: 2px dashed var(--ion-color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-image-pdp-default img {
    width: 40%;
    height: 40%;
}