.height-imagurl {
    height: 285px;
}
.text-floating {
    position:absolute;
    bottom: 10px;
    left: 10px;
}
.border-width-none {
    --border-width: 0px !important
}