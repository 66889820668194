.outline {
  background-color: var(--ion-color-medium-shade);;
}
.filled {
  background-color: var(--ion-color-primary);
}
.trait {
  display: inline-block;
  width: 100%;
  height: 2px;
  margin: 0px 4px;
  border-radius: 8px;
}
.row {
  padding: 25px;
  background-color: transparent;
}

.trait-round {
  display: inline-block;
  width: 25px !important;
  height: 5px;
  margin: 0px 4px;
  border-radius: 8px;
  padding: 0px !important;
}
.trait-round-small {
  width: 8px !important;
  height: 8px !important;
}