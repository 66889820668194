.box-blur{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 100px;
    border-radius: 10px;
    z-index: 50;
}
.border-radius-bottom {
    border-bottom-left-radius: 8%;
    border-bottom-right-radius: 8%;
}
.border-white-bottom{
    border-bottom: 0.5px solid white;
}
.profil-segment .segment-button-checked {
    background: #2F3B6F;
}
.no-radius{
    border-radius : 0;
}