.widthIcon {
  width: 30%;
  height: auto;
}

.margin-auto {
  position: relative;
  margin: 0 auto;
  display: block;
}

.font-description {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin: 32px 0;
}
.margin-top-header {
  margin-top: 100px !important;
}
.font-description-2 {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  margin-top: 30px;
  margin-bottom: 0px;
}