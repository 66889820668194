.full {
    width : 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}
.header-height {
    height: 50%;
}
.bg-gradient {
    background: linear-gradient(D, #04B171);
    height: 100%;
    width: 100%;
}
.dispo-header-title {
    background: #04B171;
    width: 100%;
    height: auto;
    margin-bottom: 3px;
    padding-left: 5%;
}

.white {
    color: #F1F1F1;
}

.float-right{
    float: right;
}

.ion-block {
    display: block;
}
.dispo-header_status {
    position: absolute;
    bottom: 5px;
}
.margin-inline {
    margin-inline: 16px;
}
.text-dispo-detail {
    font-size: 22px;
    font-weight: 600;
}